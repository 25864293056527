import React,{useEffect} from 'react';
import image from '../../Images/Pages/about.png';
import img2 from '../../Images/Pages/contact.png';
import Layout from '../../Components/Layout/Layout';
import wow from 'wowjs';

function About () {

useEffect(()=>{
    new wow.WOW().init();
},[]);

  return (
    <>
    <Layout>
      
    <div className="contact-img" >
                <img src={img2}/>
                <h1 className="text-capitalize fw-bold" style={{color:'#363d48'}}>About Us</h1>
            </div>
      <section className='about-us-section mt-5'>
        <div className='container'>
          <div className='row'>
            <div className='col-sm-7 wow animate__animated animate__slideInLeft' data-wow-duration="1s" data-wow-delay="0.2s">
              <h1>About Alhijaz Tours</h1>
              <p>
                Alhijaz Tours is well known within the Hajj and Umrah industry.
                We have a highly experienced team who know the ins and outs of
                Umrah and Hajj. We have immense experience on how traveling and
                accommodation work within Makkah and Medinah so we can make it
                easier for all pilgrims. Additionally, we have an abundance of
                knowledge on how one needs to perform their Hajj or Umrah and
                what they will need to take with them on their journey. We
                provide all the necessary information and keep our pilgrims
                updated at all times to make your travels easier. Our highly
                experienced and well versatile team is ready to take your
                queries.
              </p>
            </div>
            <div className='col-sm-5 wow animate__animated animate__slideInRight' data-wow-duration="1s" data-wow-delay="0.2s">
              <img src={image} alt='' />
            </div>
          </div>
          <div className="wow animate__animated animate__fadeInUp" data-wow-duration="1s" data-wow-delay="0.2s">
          <h3 className='mt-5'>Our Goals!</h3>
          <p>
            Here at Alhijaz Tours we strive to provide the most desirable Hajj
            and Umrah packages, which are carefully crafted, by our highly
            experienced team to allow you to perform your religious obligations
            with simplicity. We take into account how tiring the journey can be
            so we always have a team ready to guide you and take care of
            everything during your travels; so you can be tension-free while
            visiting the divine land of Makkah &amp; Medinah! Here at Alhijaz
            Tours we have been diligent in making close contacts and
            partnerships with highly renowned hotels which are in close
            proximity to the Haram in Makkah and Masjid Al Nabawi in Medina. Our
            professional and experienced staff members are always willing to
            help you to ensure that your journey remains memorable and
            trouble-free. Our objective of quality services and that is
            reflected from the commitment and experience of our team. We always
            feel pride in offering the Hajj and Umrah service with the best
            cost-effective Hajj packages and Umrah. We keep striving and working
            to keep expanding our operations and we are constantly improving our
            network in order to provide the highest level of professional
            services with low-cost hajj packages and low-cost Umrah packages to
            those wanting to visit the majestic land of Makkah &amp; Medinah.
          </p>
          </div>
          <div className=' row about-4' >
            <div className='wow  animate__animated animate__slideInLeft col-sm-6' data-wow-duration="1s" data-wow-delay="0.3s">
              <h3>PROFESSIONALISM</h3>
              <p>
                All our team is professional and they have years of experience
                to deal with all the issues. Our team treats hajj and umrah
                pilgrims with respect and provides them the required help
                whenever they need any assistance with our best umrah packages.
              </p>
            </div>
            <div className='wow animate__animated animate__slideInRight col-sm-6' data-wow-duration="1s" data-wow-delay="0.3s">
              <h3>COMMUNICATION</h3>
              <p>
                All our team is professional and they have years of experience
                to deal with all the issues. Our team treats hajj and umrah
                pilgrims with respect and provides them the required help
                whenever they need any assistance with our best umrah packages.
              </p>
            </div>
            <div className='wow animate__animated animate__slideInLeft col-sm-6' data-wow-duration="1s" data-wow-delay="0.3s">
              <h3>KNOWLEDGE</h3>
              <p>
                Our team possesses detailed knowledge of all the procedures of
                Hajj and Umrah services including Visa requirements, airport
                immigration, transportation arrangements, accommodation,
                ziyaraat and others. You get all the guidance which helps you to
                perform hajj and umrah in their true spirit.
              </p>
            </div>
            <div className='wow animate__animated animate__slideInRight col-sm-6' data-wow-duration="1s" data-wow-delay="0.3s">
              <h3>TEAMWORK</h3>
              <p>
                All the people in our team work with collaboration and they are
                always willing to help the valuable clients to meet their
                expectations.
              </p>
            </div>
          </div>
        </div>
      </section>
      </Layout>
    </>
  )
}

export default About
